import { useRouter as useOriginalRouter } from 'next/router';
import { LANGUAGES } from '@vaza-eu/shoelessly';

export type Language = 'sk' | 'en' | 'cs';

export const useRouter = () => {
  const router = useOriginalRouter();

  const shallowReload = async () => {
    await router.replace(router.asPath, router.asPath, { scroll: false, shallow: true });
  };

  const clearPath = router.asPath.split('?')[0];

  const locale = (router.locale || router.defaultLocale) as Language;

  return {
    ...router,
    locale,
    locales: LANGUAGES.filter(lang => lang === 'sk' || lang === 'en' || lang === 'cs'),
    shallowReload,
    clearPath,
  };
};
