import styled from 'styled-components';
import { desktopMedia } from '@vaza-eu/vaza/dist/styles/devices';

export const StyledWrapper = styled.div`
  background-color: white;
  border-radius: 28px;
  padding: 20px 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  @media (${desktopMedia}) {
    gap: 5px;
    flex-wrap: wrap;
    border-radius: 48px;
    padding: 20px 25px 40px;
    flex-direction: column;
  }
`;

export const StyledDoctorCardContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5px;

  @media (${desktopMedia}) {
    flex-direction: column;
  }
`;

export const StyledImageWrapper = styled.div`
  position: relative;
  width: 50px;
  min-width: 50px;
  height: 50px;
  min-height: 50px;
  overflow: hidden;
  margin: 0 0 auto;

  img {
    border-radius: 50%;
    object-fit: contain;
  }

  @media (${desktopMedia}) {
    width: 140px;
    height: 140px;
  }
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (${desktopMedia}) {
    padding-top: 20px;
  }
`;

export const StyledTitleText = styled.span`
  font-family: Futura Extra Black Condensed;
  color: #e31337;
  font-size: 24px;
  padding-bottom: 4px;
  border-bottom: 1px solid #efefef;

  @media (${desktopMedia}) {

  }
`;

export const StyledFavoriteWrapper = styled.div`
  display: none;

  @media (${desktopMedia}) {
    display: block;
    cursor: pointer;
    padding-right: 2px;
  }
`;

export const StyledSpecialization = styled.div`
  font-family: Apercu Pro;
  display: inline;
  width: 100%;
  gap: 5px;
  text-align: left;
  font-size: 14px;

  * {
    display: inline;
  }

  @media (${desktopMedia}) {
    font-size: 16px;
  }
`;

export const StyledSpecializationName = styled.span`
  font-family: Futura Extra Black Condensed;
  text-transform: uppercase;
`;

export const StyledDescription = styled.div`
  font-family: Apercu Pro;
  padding-top: 5px;
  color: #414146;
  font-size: 14px;
`;

export const StyledSelectedTimeInfo = styled.div`
  display: inline;
  flex-direction: column;
  gap: 15px;
  padding: 5px 0 0;
  width: 100%;

  @media (${desktopMedia}) {
    padding: 20px 0 10px;
    gap: 30px;
  }
`;

export const StyledSelectedTimeInfoText = styled.div`
  font-family: Futura Extra Black Condensed;
  font-size: 20px;
  display: inline;

  * {
    text-wrap: wrap;
    display: inline;
  }
`;

export const StyledSelectedTimeInfoTextComponent = styled.span`
  color: #e31337;
  padding: 0 4px;
  text-transform: uppercase;
`;

export const StyledSelectedTimeInfoConfirmation = styled.div`
  font-family: Apercu Pro;
`;

export const StyledNoLongerAvailableBig = styled.div`
  display: inline;
  text-transform: uppercase;
  font-family: "Futura Extra Black Condensed";
  font-size: 20px;
  text-align: left;
  white-space: normal;
  max-width: 100%;

  * {
    display: inline;
  }

  @media (${desktopMedia}) {
    font-size: 32px;
  }
`;

export const StyledNoLongerAvailableSmall = styled.div`
  font-size: 16px;
  font-family: Apercu Pro;
  margin-top: 10px;

  * {
    display: inline;
  }

  @media (${desktopMedia}) {
    font-size: 20px;
    margin-top: 20px;
  }
`;